import { useState } from 'react';
import _, { set } from 'lodash';
import qs from 'qs';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { apiAuth } from '../services/auth';
import { apiProducts } from '../services/products';
import { apiUlm } from '../services/ulm';
import ScreenNames from '../constants/ScreenNames';
import useToken from './useToken';
import useUser from './useUser';
import { apiSubscriptions } from '../services/subscriptions';
import { apiAccounts } from '../services/accounts';

const { REACT_APP_GOOGLE_REDIRECT } = process.env;


export default function useMarket() {

    let APP_NAME = 'GOOGLE';

    const navigate = useNavigate();

    const { setTokenData, getGoogleTokenData, setGoogleTokenData } = useToken();
    const { setUser } = useUser();

    const refreshToken = async() => {
        try {
            const res = await apiAuth.refreshToken({ app: APP_NAME });
            await setTokenData(res.data);            
        } catch (e) {
            console.log(e);
            // navigate(ScreenNames.GOOGLE.AuthenticationFailure);
            return false;
        }
    }

    const fetchProduct = async(id, appName) => {
        try {
            await setAppName(appName);
            const res = await apiProducts.get(id);
            return res.data;

        } catch (e) {
            console.log(e);
            generalFailure();
            
        }
    }

    const checkSubscriptionStatus = async() => {
        try {

            await refreshToken();

            //get account id
            const { accountId, type } = await getAccount();

            if ((APP_NAME === 'PRM') && (type === 'prepaid') && (process.env.REACT_APP_ALLOW_PRM_PREPAID === 'false')) {
                generalFailure();
                return false;
            }

            const res = await apiSubscriptions.getPartnerSubscriptions( { accountId } );            

            return _.filter(res.data, i => i.type === 'ENTITLEMENT');

        } catch (e) {
            console.log(e);            
            switch (_.get(e, 'response.status')) {
                case 401:
                    //Access token expired
                    //await refreshToken();
                    //await checkSubscriptionStatus();
                    return generalFailure();
                case 403:
                    return navigate(ScreenNames[APP_NAME].AuthenticationFailure);
                case 404:
                    //Partner subscription not found
                    return [];
                case 500:
                    return navigate(ScreenNames[APP_NAME].AuthenticationFailure);
                default:
                    return generalFailure();
            }
            
        }
    }

    const getProduct = () => {
        const dataString = localStorage.getItem('product');
        const data = dataString ? JSON.parse(dataString) : {};
        return data || {
            id: '',
            prices: {
                data: [
                    {
                        amount: 0,
                    }
                ]
            },
            descriptions: {
                data: [
                    {
                        description: ''
                    }
                ]
            }
        };
    };

    const getProductId = () => {
        const dataString = localStorage.getItem('productId');
        const data = dataString;
        return data || '';
    };

    const ulmFlow = async({ redirectUri = ScreenNames.GOOGLE.ManageSubscription, productId = null, appName = 'GOOGLE' }) => {
        try {     
            setTokenData({});
            await setAppName(appName);

            let clientId = ''
            if(appName === 'GOOGLE') {
                clientId = process.env.REACT_APP_M1_TOKENS_USERNAME;
            }
            if(appName === 'PRM') {
                clientId = process.env.REACT_APP_M1_TOKENS_PRM_USERNAME;
            }

            const res = await apiAuth.authorize({ 
                redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT + redirectUri, 
                clientId: clientId,
                productId,
                app: appName
            });
            
            window.location.href = res;
        } catch (e) {
            console.log(e);
            generalFailure();
        }
    }

    const getToken = async(code, redirectUri = ScreenNames.GOOGLE.ManageSubscription, appName = 'GOOGLE', productId) => {
        try {

            await setAppName(appName);

            const res = await apiAuth.getToken({ 
                authCode: code, 
                redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT + redirectUri,
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                app: appName,
            });

            setTokenData(res.data);
            

        } catch (e) {
            console.log(_.get(e, 'response.data', false));
            if(_.get(e, 'response.data.error') === 'invalid_client') return generalFailure();
            // if(_.get(e, 'response.data.error') === 'invalid_grant') await ulmFlow({ redirectUri: ScreenNames.GOOGLE.AcceptTerms, productId: productId });
            else return generalFailure();
        }
    }

    const getUserInfo = async() => {
        try {
            // await refreshToken();
            const res = await apiAuth.getUser();
            setUser(res.data);

        } catch (e) {

            switch (_.get(e, 'response.status')) {
                case 401:
                    //await refreshToken();
                    //await getUserInfo();
                    break;
            
                default:
                    
                    break;
            }
        }
    }

    const fetchProducts = async(appName) => {
        try {
            await setAppName(appName);

            let app =  'GOOGLE1';
            if(APP_NAME === 'GOOGLE') app = 'GOOGLE1';
            if(APP_NAME === 'PRM') app = 'PPLSMX';

            const res = await apiProducts.list({ status: 'Active', 'metadata.app-name': app });
            return qs.parse(res.data).data;
        } catch (e) {
            console.log(e);
            generalFailure();
        }
    }

    const fetchUserSubscriptions = async( payload ) => {
        try {
            await refreshToken();
            const res = await apiUlm.getUserSubscriptions( payload );            
            return res.data;
        } catch (e) {
            console.log(e);
            switch (_.get(e, 'response.status')) {
                case 401:
                    //Access token expired
                    //await refreshToken();
                    //await fetchUserSubscriptions( payload );
                    break;            
                default:
                    generalFailure();
                    break;
            }
        }
    }

    const fetchUserAccounts = async( payload ) => {
        try {
            await refreshToken();
            // log token data in storage
            const res = await apiUlm.getUserAccounts( payload );   
            // console.log("User Accounts", res)         
            return res.data;
        } catch (e) {
            console.log(e);            
            switch (_.get(e, 'response.status')) {
                case 401:
                    //Access token expired
                    //await refreshToken();
                    //await fetchUserAccounts( payload );
                    break;            
                default:
                    generalFailure();
                    break;
            }
        }
    }

    const subscriptionValidations = async( subscriptionId, appName = 'GOOGLE' ) => {
        try {           

            await setAppName(appName); 
            
            //get partner subscriptions
            const subscriptions = await checkSubscriptionStatus();
            // run checks on subscriptions per product id
            // check if product id exists in subscriptions
            const filteredSubscriptions = _.filter(subscriptions, i => i.slmProductCode === subscriptionId);

            //if not subscriptions match the selected subscription Id, return false.  User has not had product before.
            if(_.size(filteredSubscriptions) === 0) return true; //elible for free trial with this product
            else {
                
                // If customer has had p+ at any time, dont allow free trial
                if(APP_NAME === 'PRM') return false;

                //Check if user already had product less than 24 hours
                for await(const i of filteredSubscriptions) {
                    const start = moment(i.createdDate);
                    const end = moment(i?.endServiceDate);
                    const hours = end.diff(start, 'hours');
                    
                    if(hours < 24) return true;
                    else return false;
                }
            }

        } catch (e) {
            generalFailure();
        }
    }

    const getAccount = async() => {
        try {
            const accounts = await fetchUserAccounts();

            // Filter for bssName=att_mx
            const filtered = _.filter(accounts, i => (i.attributes.bssName === 'att_mx') && (((_.lowerCase(i.type)) === 'postpaid') || ((_.lowerCase(i.type)) === 'prepaid')));

            return { accountId: filtered[0].attributes.bssAccounts[0].accountId, merchantAccountId: filtered[0].attributes['com.uxpsystems.mint.account.AccountNumber'], phone: filtered[0].attributes.bssAccounts[0].externalId, type: _.lowerCase(filtered[0].type) };

        } catch (e) {
            return generalFailure();
        }
    }

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const setAppName = async(appName) => { 
        if(appName) APP_NAME = appName; 
    }


    const createSubscription = async( { productId, trial, dryRun, appName, trackingId, campaignCode } ) => {
        try {
            
            await setAppName(appName); // set the app name to google / paramount / etc.
            await refreshToken();

            console.log(`Create Subscription - Dry Run: ${dryRun}`)

            let billingId = '';

            if(APP_NAME === 'GOOGLE') {
                billingId = process.env.REACT_APP_GOOGLE_MONTHLY_PLAN;
                if(trial) billingId = process.env.REACT_APP_GOOGLE_TRIAL_PLAN;
            }

            if(APP_NAME === 'PRM') {
                billingId = process.env.REACT_APP_PRM_MONTHLY_PLAN;
                // if(trial) billingId = process.env.REACT_APP_PRM_TRIAL_PLAN;
            }

            const { accountId, phone, type, merchantAccountId } = await getAccount();

            if(APP_NAME === 'PRM') {
                if(type === 'prepaid' && (process.env.REACT_APP_ALLOW_PRM_PREPAID === 'false')) {
                    generalFailure();
                    return false;
                }
            }

            const accountData = await apiAccounts.get(accountId);

            const account = accountData.data;

            // Filter payment account for postpaid
            let paymentMethod = {};
            if(APP_NAME === 'GOOGLE') {
                paymentMethod = _.find(account?.payment_methods?.data, i => (((_.lowerCase(i.merchant_accepted_payment.payment_type) === 'postpaid') || ((_.lowerCase(i.merchant_accepted_payment.payment_type) === 'prepaid'))) && (i.active === true) && (i.primary === true)));
            }

            if(APP_NAME === 'PRM') {
                paymentMethod = _.find(account?.payment_methods?.data, i => (((_.lowerCase(i.merchant_accepted_payment.payment_type) === 'postpaid') || ((_.lowerCase(i.merchant_accepted_payment.payment_type) === 'prepaid'))) && (i.active === true) && (i.primary === true)));
                if(process.env.REACT_APP_ALLOW_PRM_PREPAID === 'false' && (_.lowerCase(paymentMethod.merchant_accepted_payment.payment_type) === 'prepaid')) {
                    generalFailure();
                    return false;
                }
            }

            if(!paymentMethod?.id) {
                generalFailure();
                return false;
            }

            const payload = {
                accountId,
                productId,
                billingId,
                dryRun,
                externalId: phone,
                phone,
                paymentMethodType: paymentMethod.type,
                paymentMethodId: paymentMethod.id,
                // merchantAccount: paymentMethod.id.slice(-9),
                merchantAccount: merchantAccountId
            }; 

            if((trackingId !== 'null') || (!trackingId)) payload.trackingId = trackingId;
            if((campaignCode !== 'null') || (!campaignCode)) payload.campaignCode = campaignCode;

            const res =  await apiSubscriptions.create(payload);   

            return res.data;

        } catch (e) {

            switch (_.get(e, 'response.status')) {
                case 401:
                    //Access token expired
                    //await refreshToken();
                    //await createSubscription( { productId, trial, dryRun } );
                    generalFailure();
                    break;            
                case 500:
                    //account may not be postpaid
                    if(e.response.data.error) console.log(e.response.data.error);
                    else console.log(e.response.data.message);
                    
                    generalFailure();
                    break;            
                default:
                    generalFailure();
                    break;
            }
        }
    }

    const googleAuth = async() => {
        try {
            if(getGoogleTokenData()?.refresh_token) {
                await refreshGoogleToken();
                await revokeGoogleToken();
            }
            const res = await apiAuth.googleAuth({
                redirectUri: REACT_APP_GOOGLE_REDIRECT + ScreenNames.GOOGLE.ActivateSubscription
            });
            window.location.href = res;
        } catch (e) {
            console.log(_.get(e, 'response'));
            authFailure();
        }
    }

    const googleAuthRaw = async() => {
        try {
            setGoogleTokenData({});
            const res = await apiAuth.googleAuth({
                redirectUri: REACT_APP_GOOGLE_REDIRECT + ScreenNames.GOOGLE.ActivateSubscription
            });
            window.location.href = res;
        } catch (e) {
            console.log(_.get(e, 'response'));
            authFailure();
        }
    }

    const activateGoogle = async(partnerSubscriptionId) => {
        try {
            const res = await apiSubscriptions.activateGoogleOne(partnerSubscriptionId);
            await revokeGoogleToken();
            navigate(ScreenNames.GOOGLE.ActivationConfirmation);
        } catch (e) {
            console.log(e);
            switch (_.get(e, 'response.status')) {
                case 400:
                    if(_.get(e, 'response.data.message') === 'Token has been expired or revoked.') {
                        await refreshGoogleToken();
                        await googleAuthRaw();
                        return false;
                    }
                    
                    await revokeGoogleToken();
                    navigate(ScreenNames.GOOGLE.GoogleError, { state: { error: e.response.data } });
                    break;            
                case 401:
                    await revokeGoogleToken();
                    await googleAuth();
                    break;            
                case 404:
                    await revokeGoogleToken();
                    generalFailure();
                    break;            
                case 409:
                    await revokeGoogleToken();
                    generalFailure();
                    break;            
                case 500:
                    await revokeGoogleToken();
                    generalFailure();
                    break;            
                case 502:
                    await revokeGoogleToken();
                    generalFailure();
                    break;            
                default:
                    await revokeGoogleToken();
                    generalFailure();
                    break;
            }
        }
    }

    const refreshGoogleToken = async() => {
        try {
            const res = await apiAuth.googleAuthRefresh();
            setGoogleTokenData({ ...getGoogleTokenData(), access_token: res.data.access_token });
        } catch (e) {
            console.log(e);
        }
    }

    const revokeGoogleToken = async() => {
        try {
            await apiAuth.googleAuthRevoke();
            setGoogleTokenData({});
        } catch (e) {
            console.log(e);
            switch (_.get(e, 'response.status')) {
                case 400:
                    if(_.get(e, 'response.data.error_description') === 'Token expired or revoked') {
                        await refreshGoogleToken();
                        await googleAuthRaw()
                    }
                    break;
            
                default:
                    break;
            }
        }
    }

    const googleAuthentication = async(code) => {
        try {

            setGoogleTokenData({});
            // wait 500ms
            await timeout(500);
            const res = await apiAuth.googleAuthTokens({ code, redirectUri: REACT_APP_GOOGLE_REDIRECT + ScreenNames.GOOGLE.ActivateSubscription });
            
            const resTokenData = res.data;
            //if refresh token stored
            setGoogleTokenData(resTokenData);
        } catch (e) {
            console.log(e);
        }
    }

    const getCurrentPartnerSub = async(enrolling, appName) => {

        await setAppName(appName);

        //Get partner subs
        const partnerSubs = await checkSubscriptionStatus();

        let partnerCode = 'GOOGL1';

        if(APP_NAME === 'PRM') partnerCode = 'PPLSMX';

        const partnerSubscription = _.filter(partnerSubs, i => (i.partnerCode === partnerCode) && (i.serviceStatus !== 'CANCELLED'))[0];

        //If no partner sub, redirect to select product
        if(!enrolling && !partnerSubscription) navigate(ScreenNames[APP_NAME].Purchase);

        let productData = {};
        if(partnerSubscription?.slmProductCode) {
            productData = await fetchProduct(partnerSubscription?.slmProductCode);
        }
        

        return { partnerSubscription, productData, enrolling: enrolling };
        

    }

    const cancelSubscription = async( { id, dryRun = true, redirect = false, appName, partnerSubscription = {}, product = {}, disentitle = false } ) => {
        try {

            await setAppName(appName);

            const res = await apiSubscriptions.cancel({ id, dryRun, disentitle });
            if(dryRun) return res.data;
            else {
                if(redirect) attRedirect();     
                else return res.data;          
            }

            return res;
        } catch (e) {
            switch (_.get(e, 'response.status')) {
                case 400:
                    if(_.get(e, 'response.data.message') == 'Unable to pause Subscription not in Good Standing.') return navigate(ScreenNames[APP_NAME].CancelConfirmation, { state: { status: 400, canceledSubscription: partnerSubscription, product }});
                    else generalFailure();
                case 409:
                    return navigate(ScreenNames[APP_NAME].CancelConfirmation, { state: { status: 409, canceledSubscription: partnerSubscription }});     
                default:
                    return generalFailure();
            }            
        }
    }

    const getTrackingId = () => {
        const dataString = localStorage.getItem('trackingId');
        const data = dataString;
        return data || '';
    };

    const [product, setProduct] = useState(getProduct());
    const [productId, setProductId] = useState(getProductId());
    const [trackingId, setTrackingId] = useState(getTrackingId());

    const saveProduct = data => {
        localStorage.setItem('product', JSON.stringify(data));
        setProduct(data);
    };
    const saveProductId = data => {
        localStorage.setItem('productId', data);
        setProductId(data);
    };
    const saveTrackingId = data => {
        localStorage.setItem('trackingId', data);
        setTrackingId(data);
    };

    const generalFailure = () => navigate(ScreenNames[APP_NAME].GeneralFailure);
    const authFailure = () => navigate(ScreenNames[APP_NAME].AuthenticationFailure);
    const attRedirect = () => window.location.href = 'https://www.att.com.mx/entretenimiento';
    const openAttWindow = () => window.open('https://www.att.com.mx/entretenimiento');
    const paramountCodeRedirect = (url) => window.open(url);

    return {
        refreshToken,
        fetchProduct,
        checkSubscriptionStatus,
        product,
        setProduct: saveProduct,
        ulmFlow,
        getToken,
        getUserInfo,
        fetchProducts,
        fetchUserSubscriptions,
        fetchUserAccounts,
        subscriptionValidations,
        createSubscription,
        googleAuth,
        activateGoogle,
        googleAuthentication,
        getCurrentPartnerSub,
        cancelSubscription,
        refreshGoogleToken,
        attRedirect,
        openAttWindow,
        revokeGoogleToken,
        setProductId: saveProductId,
        productId,
        getProductId,
        paramountCodeRedirect,
        setTrackingId: saveTrackingId,
        getTrackingId,
        trackingId
    }
}
